<div class="flex flex-auto py-5 px-5 items-center gap-3">
    <img src="../../../../assets/img/logo/logo.png" alt="Logo" class="w-10 select-none flex-none" />
    <p class="text-[24px] font-bold"> AD Value </p>
</div>


<div class="flex flex-auto justify-center py-10 px-5 items-center gap-3 flex-col">
    <img src="../../../../assets/img/unauthorized.svg" alt="Logo" class="w-[300px] select-none flex-none" />
    <p class="text-[24px] font-bold"> Unauthorized! </p>
    <p class="text-center">Access to this page is restricted based on the user role currently logged in.</p>
    <button mat-flat-button color="primary" class="py-6 px-4" (click)="goToDashboard()">Go to Home</button>
</div>