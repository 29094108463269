import { Injectable } from '@angular/core';
import { VexLayoutService } from '@vex/services/vex-layout.service';
import { NavigationItem } from './navigation-item.interface';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationLoaderService {
  private readonly _items: BehaviorSubject<NavigationItem[]> =
    new BehaviorSubject<NavigationItem[]>([]);

  get items$(): Observable<NavigationItem[]> {
    return this._items.asObservable();
  }

  constructor(private readonly layoutService: VexLayoutService) {
    this.loadNavigation();
  }

  loadNavigation(): void {
    this._items.next([
      {
        type: 'subheading',
        label: 'Menu',
        children: [
          {
            type: 'link',
            label: 'Analytics',
            route: 'dashboard',
            icon: 'mat:insights',
            routerLinkActiveOptions: { exact: true },
            roles: ['Admin', 'AdsManager', 'ReportViewer','AdsChecker']
          },
          {
            type: 'link',
            label: 'Clients',
            route: 'client/list',
            icon: 'mat:playlist_add_check',
            routerLinkActiveOptions: { exact: false },
            roles: ['Admin', 'AdsManager']
          },
          {
            type: 'link',
            label: 'Campaigns',
            route: 'campaign/list',
            icon: 'mat:store',
            routerLinkActiveOptions: { exact: false },
            roles: ['Admin', 'AdsManager','AdsChecker']
          },
          {
            type: 'link',
            label: 'Campaigns Pending for Approval',
            route: 'campaignapproval/list',
            icon: 'mat:store',
            routerLinkActiveOptions: { exact: false },
            roles: ['Admin','AdsManager','AdsChecker']
          },
          {
            type: 'link',
            label: 'Campaign Audit Log',
            route: 'campaign/aduitlog',
            icon: 'mat:store',
            routerLinkActiveOptions: { exact: false },
            roles: ['Admin','AdsChecker']
          },
          {
            type: 'link',
            label: 'Advertisements',
            route: 'advertisement/list',
            icon: 'mat:store',
            routerLinkActiveOptions: { exact: false },
            roles: ['Admin', 'AdsManager','AdsChecker']
          },
          {
            type: 'link',
            label: 'Ads Pending for Approval',
            route: 'adsapproval/list',
            icon: 'mat:store',
            routerLinkActiveOptions: { exact: false },
            roles: ['Admin','AdsManager','AdsChecker']
          },
          {
            type: 'link',
            label: 'Ads Audit Log',
            route: 'advertisement/aduitlog',
            icon: 'mat:store',
            routerLinkActiveOptions: { exact: false },
            roles: ['Admin','AdsChecker']
          },
          {
            type: 'link',
            label: 'Weightages',
            route: 'weightage/list',
            icon: 'mat:store',
            routerLinkActiveOptions: { exact: false },
            roles: ['Admin', 'AdsManager']
          },
          {
            type: 'link',
            label: 'Budgets',
            route: 'budget/list',
            icon: 'mat:store',
            routerLinkActiveOptions: { exact: false },
            roles: ['Admin', 'AdsManager']
          },
          {
            type: 'link',
            label: 'Users',
            route: 'user/list',
            icon: 'mat:playlist_add_check',
            routerLinkActiveOptions: { exact: false },
            roles: ['Admin','AdsChecker']
          },
          {
            type: 'link',
            label: 'User Audit Log',
            route: 'user/aduitlog',
            icon: 'mat:store',
            routerLinkActiveOptions: { exact: false },
            roles: ['Admin','AdsChecker']
          },
          {
            type: 'dropdown',
            label: 'Reports',
            icon: 'mat:assignment',
            roles: ['Admin', 'AdsManager', 'ReportViewer','AdsChecker'],
            children: [
              {
                type: 'link',
                label: 'By Campaign',
                route: 'reports/campaign'
              },
              {
                type: 'link',
                label: 'By Advertisement',
                route: 'reports/ads'
              },
              {
                type: 'link',
                label: 'By Daywise',
                route: 'reports/daywise'
              },
              {
                type: 'link',
                label: 'By OS',
                route: 'reports/os'
              },
              {
                type: 'link',
                label: 'By Language',
                route: 'reports/lang'
              },
              {
                type: 'link',
                label: 'By Page',
                route: 'reports/page'
              },
              {
                type: 'link',
                label: 'By Country',
                route: 'reports/country'
              },
              {
                type: 'link',
                label: 'By ProcessedData',
                route: 'reports/ProcessedData'
              },
              {
                type: 'link',
                label: 'By Ads Display Report',
                route: 'reports/AdsDisplayReport',
                roles: ['Admin']
              },
              {
                type: 'link',
                label: 'By Ads Display Report Summary',
                route: 'reports/adDisplayReportSummary',
                roles: ['Admin']
              },
              {
                type: 'link',
                label: 'By Daywise Consolidate Report',
                route: 'reports/DaywiseConsolidateReport',
                roles: ['Admin']
              },
              {
                type: 'link',
                label: 'By Advertisement Wise Consolidate Report',
                route: 'reports/AdvertisementWiseConsolidate',
                roles: ['Admin']
              },
              {
                type: 'link',
                label: 'By Statistics Report',
                route: 'reports/StatisticsReport',
                roles: ['Admin']
              },
              {
                type: 'link',
                label: 'By Campaign Wise Consolidated Report',
                route: 'reports/CampaignWiseConsolidatedReport',
                roles: ['Admin']
              }

            ]
          }
        ]
      }
    ]);
  }
}
