import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getUrl } from 'aws-amplify/storage';
import { environment } from 'src/environments/environment';
import { AppInitializerService } from './app-initializer.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private headers: HttpHeaders;
  private baseUrl = environment.apiUrl;
  constructor(
    private snackBar: MatSnackBar,
    private http: HttpClient
  ) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.set('Content-Type', 'application/json');
  }

  getNestedProperty(obj: any, path: string): any {
    return path.split('.').reduce((o, p) => (o ? o[p] : null), obj);
  }

  openSnackbar(statusText: string): void {
    this.snackBar.open(statusText, 'CLOSE', {
      duration: 30000,
      horizontalPosition: 'center'
    });
  }

  formatDate(time: string | number | Date) {
    let date = new Date(time);
    let day = date.getUTCDate();
    let month = date.getUTCMonth() + 1; // Months are 0-based
    let year = date.getUTCFullYear();
    let hours = date.getUTCHours();
    let minutes: any = date.getUTCMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;
    return strTime;
  }

  getDateFromString(date: any) {
    return new Date(new Date(date).setHours(new Date(date).getHours() - 3));
  }

  getDateFromStringForEdit(date: any) {
    return new Date(new Date(date).setHours(new Date(date).getHours()));
  }

  convertToBase64(file: any): Promise<string> {
    return new Promise((resolve, reject) => {
      if (file) {
        const reader = new FileReader();

        reader.onload = () => {
          const base64String = reader.result as string;
          resolve(base64String);
        };

        reader.onerror = (error) => {
          reject(error);
        };

        reader.readAsDataURL(file);
      } else {
        reject('No file provided');
      }
    });
  }

  setAuthToken(token: string) {
    this.headers = this.headers.set('Authorization', token);
  }

  deleteCloudFrontCache(): Observable<any> {
    const apiUrl = this.baseUrl + '/admin/campaign/invalidate';
    return this.http.get<any>(apiUrl, { headers: this.headers });
  }
}
