import { Injectable } from '@angular/core';
import { getAuthToken } from '../helpers/auth-helper';

@Injectable({
  providedIn: 'root'
})
export class AppInitializerService {
  authToken: string | undefined;

  constructor() {}

  async initializeApp(): Promise<void> {
    const token = await getAuthToken();
    if (token) this.authToken = 'Bearer ' + token;
    else console.log('initializeApp token is undefined=>', token);
  }
}
