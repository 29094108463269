import {
  Component,
  DestroyRef,
  ElementRef,
  HostBinding,
  Inject,
  inject,
  OnInit
} from '@angular/core';
import { VexLayoutService } from '@vex/services/vex-layout.service';
import { VexConfigService } from '@vex/config/vex-config.service';
import { filter, map, startWith, switchMap } from 'rxjs/operators';
import { NavigationService } from '../../../core/navigation/navigation.service';
import { VexPopoverService } from '@vex/components/vex-popover/vex-popover.service';
import { MegaMenuComponent } from './mega-menu/mega-menu.component';
import { Observable, of } from 'rxjs';
import { NavigationComponent } from '../navigation/navigation.component';
import { ToolbarUserComponent } from './toolbar-user/toolbar-user.component';
import { ToolbarNotificationsComponent } from './toolbar-notifications/toolbar-notifications.component';
import { NavigationItemComponent } from '../navigation/navigation-item/navigation-item.component';
import { MatMenuModule } from '@angular/material/menu';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { AsyncPipe, NgClass, NgFor, NgIf,NgStyle } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NavigationItem } from '../../../core/navigation/navigation-item.interface';
import { checkRouterChildsData } from '@vex/utils/check-router-childs-data';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { getUserRole } from 'src/app/core/helpers/auth-helper';
import { AppInitializerService } from 'src/app/core/services/app-initializer.service';
import { CommonService } from 'src/app/core/services/common.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { getUserName, handleSignOut } from 'src/app/core/helpers/auth-helper';
import { VexPopoverRef } from '@vex/components/vex-popover/vex-popover-ref';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/pages/common/confirmation-dialog/confirmation-dialog.component';
import { environment } from 'src/environments/environment';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { AdService } from 'src/app/core/services/ad.service';
import moment from 'moment';
import { Imgs3downloadPipe } from 'src/app/core/pipes/imgs3downloadpipe.pipe';

@Component({
  selector: 'vex-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    NgIf,
    RouterLink,
    MatMenuModule,
    NgClass,
    NgFor,
    NavigationItemComponent,
    ToolbarNotificationsComponent,
    ToolbarUserComponent,
    NavigationComponent,
    AsyncPipe,
    MatProgressSpinnerModule
  ]
})
export class ToolbarComponent implements OnInit {

  logOut() {
    handleSignOut();
    this.router.navigate(['/']);
  }


  @HostBinding('class.shadow-b')
  showShadow: boolean = false;

  navigationItems$: Observable<NavigationItem[]> =
    this.navigationService.items$;

  isHorizontalLayout$: Observable<boolean> = this.configService.config$.pipe(
    map((config) => config.layout === 'horizontal')
  );
  isVerticalLayout$: Observable<boolean> = this.configService.config$.pipe(
    map((config) => config.layout === 'vertical')
  );
  isNavbarInToolbar$: Observable<boolean> = this.configService.config$.pipe(
    map((config) => config.navbar.position === 'in-toolbar')
  );
  isNavbarBelowToolbar$: Observable<boolean> = this.configService.config$.pipe(
    map((config) => config.navbar.position === 'below-toolbar')
  );
  userVisible$: Observable<boolean> = this.configService.config$.pipe(
    map((config) => config.toolbar.user.visible)
  );
  title$: Observable<string> = this.configService.select(
    (config) => config.sidenav.title
  );

  isDesktop$: Observable<boolean> = this.layoutService.isDesktop$;
  megaMenuOpen$: Observable<boolean> = of(false);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  isDeleteCacheSubmitting = false;

  constructor(
    private readonly layoutService: VexLayoutService,
    private readonly configService: VexConfigService,
    private readonly navigationService: NavigationService,
    private readonly popoverService: VexPopoverService,
    private readonly router: Router,
    private appInitializerService: AppInitializerService,
    private commonService: CommonService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        startWith(null),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.showShadow = checkRouterChildsData(
          this.router.routerState.root.snapshot,
          (data) => data.toolbarShadowEnabled ?? false
        );
      });
  }

  openQuickpanel(): void {
    this.layoutService.openQuickpanel();
  }

  openSidenav(): void {
    this.layoutService.openSidenav();
  }

  openMegaMenu(origin: ElementRef | HTMLElement): void {
    this.megaMenuOpen$ = of(
      this.popoverService.open({
        content: MegaMenuComponent,
        origin,
        offsetY: 12,
        position: [
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top'
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top'
          }
        ]
      })
    ).pipe(
      switchMap((popoverRef) => popoverRef.afterClosed$.pipe(map(() => false))),
      startWith(true)
    );
  }

  openSearch(): void {
    this.layoutService.openSearch();
  }

  isAllowedUser(roles: any): boolean {
    const userRoles = getUserRole(this.appInitializerService.authToken || '');
    if (!roles.some((role: any) => userRoles.includes(role))) {
      return false;
    }
    return true;
  }

  deleteCloudFrontCache(): void {
    this.isDeleteCacheSubmitting = true;
    this.commonService.deleteCloudFrontCache().subscribe({
      next: (response) => {
        // console.log('deleteCloudFrontCache Response:', response);
        if (response.status === 'success') {
          this.commonService.openSnackbar(
            'Cache clearance for CloudFront has been successfully initiated. It may take up to 15 minutes to reflect the changes!'
          );
        } else {
          this.commonService.openSnackbar(response.error);
        }
      },
      error: (error) => {
        console.log('clearCloudFrontCache Error:', error);
        this.commonService.openSnackbar('Cloudfront cache clear initiation failed.');
      },
      complete: () => {
        this.isDeleteCacheSubmitting = false;
      }
    });
  }

  openConfirmationDialog() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: { message: 'Are you sure you want to initiate the CloudFront cache clearance?' }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // User clicked "Yes," handle the confirmation logic here
        // console.log('User clicked Yes');
        this.deleteCloudFrontCache();
      } else {
        // User clicked "No," handle the cancellation logic here
        // console.log('User clicked No');
      }
    });
  }

  openAdsApiDocumentation() {
    window.open(environment.apiUrl+ '/ads', '_blank');
  }

  openPopup(){
    const dialogRef = this.dialog.open(AdDisplayPopup, {
      width: '100%',
      height: '600px',
    });
  }
}


//popup model
@Component({
  selector: 'addisplay-popup',
  templateUrl: 'addisplay-popup.html',
  standalone: true,
  imports : [
    MatTableModule,
    Imgs3downloadPipe,
    AsyncPipe,
    NgClass,
    NgIf,
    NgFor,
    NgStyle
  ]
})
export class AdDisplayPopup {
  bannerSize: any;
  displayedColumnsnew: string[] = ['image','title', 'client_name','campaign_name', 'last_modified'];
  dataSource1!: MatTableDataSource<any>;
  dataSource2!: MatTableDataSource<any>;
  last_modified:any;
  constructor(

    private adService: AdService,
    public dialogRef: MatDialogRef<AdDisplayPopup>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.getBannerSize()
      this.callApi(data?.id)
    }

    getBannerSize() {
      const bannerSize = localStorage.getItem('adBannerSize');
      if (bannerSize) {
        this.bannerSize = JSON.parse(bannerSize);
        // console.log('bannerSize', this.bannerSize);
      }
    }
    current_ads:any = [];
    upcoming_ads:any = []
    auditlog_list:any = []
    callApi(id:any){
      let data = {
        "ad_id":180,
        "limit":100,
        "offset":0
      }
      this.adService.getAdsapi().subscribe({
        next: (response:any) => {
          console.log('getAdStatus Response:', response);
          this.current_ads = response.ads;
          this.dataSource1 = new MatTableDataSource(this.current_ads)
          this.upcoming_ads = response.up_ads;
          this.dataSource2 = new MatTableDataSource(this.upcoming_ads)
        },
        error: (error:any) => {
          console.log('getAdStatus Error:', error);
        },
        complete: () => {
          // console.log('getAdStatus Complete');
        }
      });
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  dateChanges(date:any){
    return moment(date).format('DD-MM-YYYY hh:mm:ss A')
  }

}

