import { Component, Input } from '@angular/core';

@Component({
  selector: 'vex-amplify-header',
  templateUrl: './amplify-header.component.html',
  styleUrls: ['./amplify-header.component.scss'],
  standalone: true
})
export class AmplifyHeaderComponent {
  @Input() title: string = '';
  @Input() description: string = '';
}
