import { Pipe, PipeTransform } from '@angular/core';
import { AdService } from '../services/ad.service';
import { Observable, firstValueFrom } from 'rxjs';

@Pipe({
  name: 'imgs3download',
  standalone: true
})
export class Imgs3downloadPipe implements PipeTransform {
  imageUrl: any;

  constructor(private adService: AdService ) {
 
  }

  async transform(imgPath: string): Promise<string> {
    const fileDownloadData = {
      file_path: imgPath
    };
    try {
      const response = await firstValueFrom(this.adService.s3SignedUrl(fileDownloadData, 'GET'));
      if (response.status === 'success') {
        // console.log('imgs3download response.url:', response.url);
        return response.url;
      }
    } catch (error) {
      console.log('s3SignedUrl Error:', error);
    }
    return imgPath
  }

}
