import { decodeJWT, fetchAuthSession } from 'aws-amplify/auth';
import { signOut } from 'aws-amplify/auth';

export async function getAuthToken() {
  try {
    const { idToken } = (await fetchAuthSession()).tokens ?? {};
    return idToken?.toString();
  } catch (err) {
    console.log('get current auth session', err);
  }
}

export async function handleSignOut() {
  try {
    await signOut();
  } catch (error) {
    console.log('error signing out: ', error);
  }
}

export function decodeToken(token: string): any {
  try {
    // Decode the JWT token
    const decodedToken = decodeJWT(token);

    // Return the decoded token
    return decodedToken;
  } catch (error) {
    console.error('Error decoding JWT token:', error);
    return null;
  }
}

export function getUserRole(token: string): any {
  try {
    // Decode the JWT token
    const decodedToken: { [key: string]: any } = decodeJWT(token);

    // Return the role
    return decodedToken['payload']['cognito:groups'];
  } catch (error) {
    console.error('getUserRole Error:', error);
    return null;
  }
}

// get user name from token
export function getUserName(token: string): any {
  try {
    // Decode the JWT token
    const decodedToken: { [key: string]: any } = decodeJWT(token);

    // Return the role
    return decodedToken['payload']['name'] || decodedToken['payload']['email']?.split('@')[0];
  } catch (error) {
    console.error('getUserRole Error:', error);
    return null;
  }
}

// get user name from token
export function getUserNameNew(token: string): any {
  try {
    // Decode the JWT token
    const decodedToken: { [key: string]: any } = decodeJWT(token);

    // Return the role
    return  decodedToken['payload']['email'];
  } catch (error) {
    console.error('getUserRole Error:', error);
    return null;
  }
}
