import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { Observable, from, switchMap } from 'rxjs';
import { getAuthToken } from './helpers/auth-helper';
import { AppInitializerService } from './services/app-initializer.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private appInitializerService: AppInitializerService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Check if the request is going to the authentication endpoint
    if (request.url.includes('login') || request.url.includes('logout')) {
      return next.handle(request); // Skip interception for login and logout requests
    }

    // Get auth token and clone the request with the auth header
    return from(getAuthToken()).pipe(
      switchMap((authToken) => {
        // console.log('token', authToken);

        // You use authToken here
        if (authToken) {
          const authRequest = request.clone({
            setHeaders: {
              Authorization: `Bearer ${authToken}`
            }
          });
          this.appInitializerService.authToken = authToken;
          return next.handle(authRequest);
        } else {
          // If user is not authenticated, proceed with the original request
          return next.handle(request);
        }
      })
    );
  }
}
