import { ApplicationRef, Component, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { AmplifyHeaderComponent } from './pages/amplify-authenticator/amplify-header/amplify-header.component';
import { Hub, I18n } from 'aws-amplify/utils';
import { LookupService } from './core/services/lookup.service';
import { AppInitializerService } from './core/services/app-initializer.service';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { concat, filter, first, interval } from 'rxjs';

I18n.setLanguage('en');
I18n.putVocabularies({
  en: {
    'Sign in': 'SIGN IN',
    'Send code': 'SEND CODE',
    Submit: 'SUBMIT'
  }
});

@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterOutlet, AmplifyAuthenticatorModule, AmplifyHeaderComponent]
})
export class AppComponent implements OnInit {
  public formFields = {
    signIn: {
      username: {
        placeholder: 'Username',
        labelHidden: true
      },
      password: {
        placeholder: 'Password',
        labelHidden: true,
        isRequired: true
      }
    },
    confirmSignIn: {
      confirmation_code: {
        labelHidden: true
      }
    },
    setupTOTP: {
      confirmation_code: {
        labelHidden: true
      }
    },
    forceNewPassword: {
      password: {
        placeholder: 'New Password',
        labelHidden: true,
        isRequired: true
      },
      confirm_password: {
        placeholder: 'Confirm Password',
        labelHidden: true,
        isRequired: true
      }
    },
    forgotPassword: {
      username: {
        placeholder: 'Email Address',
        labelHidden: true
      }
    },
    confirmResetPassword: {
      confirmation_code: {
        labelHidden: true
      },
      password: {
        placeholder: 'New Password',
        labelHidden: true,
        isRequired: true
      },
      confirm_password: {
        placeholder: 'Confirm Password',
        labelHidden: true,
        isRequired: true
      }
    }
  };

  constructor(
    private lookupService: LookupService,
    private appInitializerService: AppInitializerService,
    private swUpdate: SwUpdate,
    appRef: ApplicationRef,
    private router: Router
  ) {
    // listen for auth events emitted by Amplify
    Hub.listen('auth', (data) => {
      const { payload } = data;
      this.onAuthEvent(payload);
    });
    const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
    const everyIntervalOf$ = interval(5 * 1 * 60 * 1000);
    const checkForUpdateAppIsStable$ = concat(appIsStable$, everyIntervalOf$);
    
    checkForUpdateAppIsStable$.subscribe(async () => {
      try {
        const updateFound = await swUpdate.checkForUpdate();
        console.log(updateFound ? 'A new version is available.' : 'Already on the latest version.');
      } catch (err) {
        console.error('Failed to check for updates:', err);
      }
    });

  }

  ngOnInit() {
    // get banner size
    const adBannerSize = localStorage.getItem('adBannerSize');
    if (!adBannerSize) {
      this.getAdBannerSize();
    }
    console.log('AppComponent ngOnInit swUpdate.isEnabled',this.swUpdate.isEnabled);
    
    this.checkForUpdate();
  }

  checkForUpdate() {
    
    if (this.swUpdate.isEnabled) {
        this.swUpdate.versionUpdates
        .subscribe(evt => {
          console.log(evt.type); // Log the event type
          if (evt.type === 'VERSION_READY' && confirm("New version available. Load New Version?")) {
            // Reload the page to update to the latest version.
            document.location.reload();
          }
        });



      // this.swUpdate.available.subscribe(() => {
      //   if (confirm("New version available. Load New Version?")) {
      //     window.location.reload();
      //   }
      // });
    }
  }

  async onAuthEvent(payload: any) {
    // calling initialize app again to get the user token after sign in
    if (payload.event === 'signedIn') {
      // console.log('onAuthEvent signIn');
      await this.appInitializerService.initializeApp();

      const adBannerSize = localStorage.getItem('adBannerSize');
      if (!adBannerSize) {
        this.getAdBannerSize();
      }
      this.router.navigate(['/dashboard']);
    }
  }

  getAdBannerSize() {
    this.lookupService.getAdImageSizes().subscribe({
      next: (response) => {
        response && response.forEach((imgSize: any) => {
          if (imgSize.image_desc.name === 'Small') {
            localStorage.setItem(
              'adBannerSize',
              JSON.stringify(imgSize.image_desc)
            );
          }
        });
      },
      error: (err) => {
        console.log('getAdImageSizes Error:', err);
      },
      complete: () => {
        // console.log('getAdImageSizes Complete');
      }
    });
  }
}
