import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppInitializerService } from './app-initializer.service';

@Injectable({
  providedIn: 'root'
})
export class AdService {
  private headers: HttpHeaders;
  private baseUrl = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.set('Content-Type', 'application/json');
  }

  getAdList(
    pageNo: any = null,
    pageSize: any = null,
    searchText: any = null,
    statusCode: any = null,
    sortBy: any = null,
    sortOrder: any = null,
  ): Observable<any> {
    const apiUrl = this.baseUrl + '/admin/ads';
    let params = new HttpParams();
    params = pageNo != null ? params.set('offset', pageNo) : params;
    params = pageSize != null ? params.set('limit', pageSize) : params;
    params = searchText != null ? params.set('title', searchText) : params;

    params = statusCode != null ? params.set('status_code', statusCode) : params;
    params = sortBy ? params.set('sort_by', sortBy) : params;
    params = sortOrder ? params.set('sort_order', sortOrder) : params;

    return this.http.get<any>(apiUrl, { params, headers: this.headers });
  }

  getAdDetails(adId: any): Observable<any> {
    const apiUrl = this.baseUrl + '/admin/ads/' + adId;
    return this.http.get<any>(apiUrl, { headers: this.headers });
  }

  getAuditlog(data: any): Observable<any> {
    const apiUrl = this.baseUrl + '/admin/ads/getAdStatusHistory';
    return this.http.post<any>(apiUrl, data, { headers: this.headers });
  }

  getAdsapi(): Observable<any> {
    const apiUrl = this.baseUrl + '/ads';
    return this.http.get<any>(apiUrl, { headers: this.headers });
  }

  getCampaignEndAdsID(id:any): Observable<any> {
    const apiUrl = this.baseUrl + '/admin/report/getCampaignEndAds/'+id;
    return this.http.get<any>(apiUrl, { headers: this.headers });
  }

  createAd(data: any): Observable<any> {
    const apiUrl = this.baseUrl + '/admin/ads';
    return this.http.post<any>(apiUrl, data, { headers: this.headers });
  }

  updateAd(adId: any, data: any): Observable<any> {
    const apiUrl = this.baseUrl + '/admin/ads/' + adId;
    return this.http.put<any>(apiUrl, data, { headers: this.headers });
  }

  s3SignedUrl(data: any, s3Type: any): Observable<any> {
    const apiUrl = this.baseUrl + '/admin/ads/signed-url/' + s3Type;
    return this.http.post<any>(apiUrl, data, { headers: this.headers });
  }

  uploadFileToS3(uploadPath: string, file: File, fields: any): Observable<any> {
    const formData = new FormData();
    Object.keys(fields).forEach((key) => formData.append(key, fields[key]));
    formData.append('file', file);

    return this.http.post(uploadPath, formData);
  }
}
