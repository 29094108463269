<amplify-authenticator
  variation="modal"
  [formFields]="formFields"
  [hideSignUp]="true">
  <ng-template amplifySlot="sign-in-header">
    <div class="mt-8">
      <vex-amplify-header
        title="Welcome back"
        description="Sign in with your credentials below."></vex-amplify-header>
    </div>
  </ng-template>
  <ng-template amplifySlot="force-new-password-header">
    <div class="mb-4">
      <vex-amplify-header
        title="Change Password"
        description="Enter the new password below."></vex-amplify-header>
    </div>
  </ng-template>
  <ng-template amplifySlot="forgot-password-header">
    <div class="mb-4">
      <vex-amplify-header
        title="Forgot Password"
        description="Enter your registered email below."></vex-amplify-header>
    </div>
  </ng-template>
  <ng-template amplifySlot="confirm-reset-password-header">
    <div class="mb-4">
      <vex-amplify-header
        title="Reset Password"
        description="Enter your new password below."></vex-amplify-header>
    </div>
  </ng-template>
  <ng-template
    amplifySlot="authenticated"
    let-user="user"
    let-signOut="signOut">
    <router-outlet></router-outlet>
  </ng-template>
</amplify-authenticator>

