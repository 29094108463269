import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { AppInitializerService } from '../services/app-initializer.service';
import { getUserRole } from '../helpers/auth-helper';

import { getUserName, handleSignOut } from 'src/app/core/helpers/auth-helper';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private appInitializerService: AppInitializerService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const requiredRoles = route.data && route.data['requiredRoles'];
    return this.checkAuth(requiredRoles);
  }

  private checkAuth(requiredRoles: string[]): boolean {
    const token = this.appInitializerService.authToken;
    // redirect to login page if token is not available
    if (!token) {
      handleSignOut();
      this.router.navigate(['/']);
      return false;
    }
    const userRoles = getUserRole(token);
    // redirect to unauthorized page if user role is not authorized
    if (!requiredRoles.some(role => userRoles.includes(role))) {
      this.router.navigate(['/unauthorized']);
      return false;
    }

    return true;
  }
}