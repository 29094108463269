import { Component, OnInit } from '@angular/core';
import { VexPopoverRef } from '@vex/components/vex-popover/vex-popover-ref';
import { MatRippleModule } from '@angular/material/core';
import { Router, RouterLink } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { handleSignOut } from 'src/app/core/helpers/auth-helper';

@Component({
  selector: 'vex-sidenav-user-menu',
  templateUrl: './sidenav-user-menu.component.html',
  styleUrls: ['./sidenav-user-menu.component.scss'],
  imports: [MatRippleModule, RouterLink, MatIconModule],
  standalone: true
})
export class SidenavUserMenuComponent implements OnInit {
  constructor(private readonly popoverRef: VexPopoverRef, private router: Router) {}

  ngOnInit(): void {}

  close(): void {
    // signing out from the app
    handleSignOut()
      .then(() => {
        /** Wait for animation to complete and then close */
        setTimeout(() => this.popoverRef.close(), 250);
        this.router.navigate(['/']);
      })
      .catch((error) => {
        console.log('error signing out: ', error);
      });
  }
}
