import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';

@Component({
  selector: 'vex-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
  ]
})
export class UnauthorizedComponent {

  constructor(private router: Router) { }

  goToDashboard() {
    console.log('Go to dashboard');
    this.router.navigate(['/dashboard']);
  }
}
