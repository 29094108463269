<div [class.container]="isVerticalLayout$ | async" class="toolbar text-default w-full px-6 flex items-center">
  <button (click)="openSidenav()" [class.hidden]="isDesktop$ | async" mat-icon-button type="button">
    <mat-icon svgIcon="mat:menu"></mat-icon>
  </button>

  <a *ngIf="isVerticalLayout$ | async" [routerLink]="['/']" class="ltr:mr-4 rtl:ml-4 flex items-center">
    <img alt="Logo" class="w-8 select-none" src="assets/img/logo/logo.svg" />
    <h1 [class.hidden]="isDesktop$ | async" class="text-2xl font-bold tracking-wide ltr:pl-4 rtl:pr-4 m-0 select-none">
      {{ title$ | async }}
    </h1>
  </a>

  <button *ngIf="isAllowedUser(['Admin','AdsManager']) && (isHorizontalLayout$ | async)"
    [class.hidden]="!(isDesktop$ | async)" [matMenuTriggerFor]="addNewMenu" color="primary" mat-flat-button
    type="button">
    Create
  </button>

  <mat-menu #addNewMenu="matMenu" [overlapTrigger]="false" xPosition="after" yPosition="below">
    <button mat-menu-item [routerLink]="['/user/add']" *ngIf="isAllowedUser(['Admin'])">
      <mat-icon svgIcon="mat:person_add"></mat-icon>
      <span> New User</span>
    </button>

    <button mat-menu-item [routerLink]="['/client/add']" *ngIf="isAllowedUser(['Admin','AdsManager'])">
      <mat-icon svgIcon="mat:person_add"></mat-icon>
      <span> New Client</span>
    </button>

    <button mat-menu-item [routerLink]="['/campaign/add']" *ngIf="isAllowedUser(['Admin','AdsManager'])">
      <mat-icon svgIcon="mat:library_add"></mat-icon>
      <span> New Campaign</span>
    </button>

    <button mat-menu-item [routerLink]="['/advertisement/add']" *ngIf="isAllowedUser(['Admin','AdsManager'])">
      <mat-icon svgIcon="mat:assignment_turned_in"></mat-icon>
      <span> New Advertisement</span>
    </button>
  </mat-menu>

  <button *ngIf="isAllowedUser(['Admin','AdsManager']) && (isHorizontalLayout$ | async)"
    [class.hidden]="!(isDesktop$ | async)" class="mx-3" color="primary" mat-flat-button type="button"
    (click)="openConfirmationDialog()" [disabled]="isDeleteCacheSubmitting">
    <mat-spinner *ngIf="isDeleteCacheSubmitting" diameter="20"></mat-spinner>
    <span *ngIf="!isDeleteCacheSubmitting">Clear Cloudfront Cache</span>
  </button>

  <button *ngIf="isAllowedUser(['Admin','AdsManager']) && (isHorizontalLayout$ | async)"
    [class.hidden]="!(isDesktop$ | async)" class="" color="primary" mat-flat-button type="button"
    (click)="openAdsApiDocumentation()">
    <span>Ads API</span>
  </button>

  <button *ngIf="isAllowedUser(['Admin','AdsManager']) && (isHorizontalLayout$ | async)"
    [class.hidden]="!(isDesktop$ | async)" class="mx-3" color="primary" mat-flat-button type="button"
    (click)="openPopup()">
    <span>Adv Details</span>
  </button>

  <div *ngIf="(isVerticalLayout$ | async) && (isNavbarInToolbar$ | async)" [class.hidden]="!(isDesktop$ | async)"
    class="px-6 flex-none flex items-center">
    <vex-navigation-item *ngFor="let item of navigationItems$ | async" [item]="item"></vex-navigation-item>
  </div>

  <span class="flex-1"></span>

  <div class="px-1">
    <vex-toolbar-user></vex-toolbar-user>
  </div>

  <div class="-mx-1 flex items-center">
    <div class="px-1">
      <div #originRef (click)="logOut()"
        class="flex items-center rounded cursor-pointer relative transition duration-400 ease-out-swift select-none py-1 pr-1 pl-3 hover:bg-hover"
        matRipple>
        <!-- <div class="body-1 font-medium leading-snug ltr:mr-3 rtl:ml-3 hidden sm:block">
          Sign Out
        </div> -->
        <div class="rounded-full h-9 w-9 flex items-center justify-center text-primary-600 bg-primary-600/10">
          <mat-icon svgIcon="mat:logout"></mat-icon>
        </div>
      </div>
    </div>
  </div>
</div>

<vex-navigation *ngIf="(isVerticalLayout$ | async) && (isNavbarBelowToolbar$ | async)"
  [class.hidden]="!(isDesktop$ | async)"></vex-navigation>