import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit
} from '@angular/core';
import { VexPopoverService } from '@vex/components/vex-popover/vex-popover.service';
import { ToolbarUserDropdownComponent } from './toolbar-user-dropdown/toolbar-user-dropdown.component';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';
import { getUserName, getUserRole } from 'src/app/core/helpers/auth-helper';
import { AppInitializerService } from 'src/app/core/services/app-initializer.service';

@Component({
  selector: 'vex-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatRippleModule, MatIconModule]
})
export class ToolbarUserComponent implements OnInit {
  dropdownOpen: boolean = false;
  userName: any;
  userRoles: any;

  constructor(
    private popover: VexPopoverService,
    private cd: ChangeDetectorRef,
    private appInitializerService: AppInitializerService
  ) {}

  ngOnInit() {
    this.getUserProfileName();
    this.userRoles = getUserRole(this.appInitializerService.authToken || '');
  }

  showPopover(originRef: HTMLElement) {
    this.dropdownOpen = true;
    this.cd.markForCheck();

    const popoverRef = this.popover.open({
      content: ToolbarUserDropdownComponent,
      origin: originRef,
      offsetY: 12,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top'
        }
      ]
    });

    popoverRef.afterClosed$.subscribe(() => {
      this.dropdownOpen = false;
      this.cd.markForCheck();
    });
  }

  getUserProfileName() {
    try {
      this.userName = getUserName(this.appInitializerService.authToken || '');
    } catch (err) {
      console.log('getUserProfileName', err);
    }
  }
}
